import { Icon, type IconifyIcon } from "@iconify-icon/react";
import type { CollectionEntry } from "astro:content";
import { useEffect, useRef, useState } from "react";

type ProgramsListProps = { programs: CollectionEntry<"programs">[] };

export default function ProgramsList({ programs }: ProgramsListProps) {
  const listRef = useRef<HTMLUListElement>(null);
  const [scrollPosition, setScrollPosition] = useState(() => {
    return listRef.current?.scrollLeft ?? 0;
  });
  const [listWidth, setListWidth] = useState(() => {
    return listRef.current?.clientWidth;
  });

  function scroll(amount: number) {
    const list = listRef.current;
    if (!list) return;
    list.scrollBy({ behavior: "smooth", left: amount });
  }

  // Monitor container width
  useEffect(() => {
    const listEl = listRef.current;

    function changeWidthVal() {
      const width = listEl?.clientWidth;
      setListWidth((prev) => width ?? prev);
    }

    if (!listEl) return;
    if (!listWidth) changeWidthVal();

    listEl.addEventListener("resize", changeWidthVal);

    return () => {
      listEl.removeEventListener("resize", changeWidthVal);
    };
  }, []);

  // Monitor scroll position
  useEffect(() => {
    const listEl = listRef.current;

    if (!listEl) return;

    function changeScrollPosition() {
      const xPosition = listEl?.scrollLeft;
      setScrollPosition((prev) => xPosition || prev);
    }

    listEl.addEventListener("scroll", changeScrollPosition);

    return () => {
      listEl.removeEventListener("scroll", changeScrollPosition);
    };
  }, []);

  return (
    <div className="relative max-w-full flex-1">
      {listWidth !== undefined && listWidth - 64 > scrollPosition && (
        <button
          className="
          pointer-events-auto absolute bottom-0 right-4 top-0 z-10 my-auto flex
          h-fit w-fit items-center justify-center rounded-[50%_50%] bg-white
          p-2 text-fsc-gray-20 drop-shadow-lg transition-all
          hover:bg-fsc-gray-20 hover:text-white hover:shadow-[0_0_20px_5px_white] md:right-8"
          type="button"
          title="Scroll programs"
          onClick={() => scroll(250)}
        >
          <Icon
            height={24}
            width={24}
            icon="bx:chevron-right"
          />
        </button>
      )}

      {listWidth !== undefined && Math.floor(scrollPosition) > 32 && (
        <button
          className="
          pointer-events-auto absolute bottom-0 left-4 top-0 z-10 my-auto flex
          h-fit w-fit items-center justify-center rounded-[50%_50%] bg-white
          p-2 text-fsc-gray-20 drop-shadow-lg transition-all
          hover:bg-fsc-gray-20 hover:text-white hover:shadow-[0_0_20px_5px_white] md:left-8"
          type="button"
          title="Scroll programs"
          onClick={() => scroll(-250)}
        >
          <Icon
            height={24}
            width={24}
            icon="bx:chevron-left"
          />
        </button>
      )}

      <ul
        ref={listRef}
        className="relative z-0 flex gap-6 overflow-x-scroll py-4 pr-8"
      >
        {programs.map((program) => {
          const id = program.id;

          return (
            <li
              key={id}
              className="h-[25rem] min-w-fit max-w-md flex-1"
            >
              <Program
                {...program.data}
                id={id}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

type ScrollButtonProps = {
  onClick: () => void;
  icon: string | IconifyIcon;
  show: boolean;
};
function ScrollButton({ onClick, icon, show }: ScrollButtonProps) {
  function handleClick() {
    onClick();
  }

  if (!show) return null;

  return (
    <div
      className="
      pointer-events-none absolute bottom-0 right-0 top-0 z-10
      flex w-60 items-center
      justify-end bg-gradient-to-l from-white to-transparent"
    >
      <button
        className="
        pointer-events-auto my-auto flex items-center justify-center rounded-[50%_50%]
        bg-white p-2 text-fsc-gray-20 drop-shadow-lg
        transition-colors hover:bg-fsc-gray-20 hover:text-white"
        type="button"
        title="Scroll programs"
        onClick={handleClick}
      >
        <Icon
          height={24}
          width={24}
          icon={icon}
        />
      </button>
    </div>
  );
}

type ProgramProps = {
  id: string;
  title: string;
  description: string;
  is_released: boolean;
  image: ImageMetadata;
};
function Program({ id, title, description, image, is_released }: ProgramProps) {
  const truncated_description = description.slice(0, 150) + "...";
  return (
    <a
      href={`/${id.toLocaleLowerCase()}`}
      className={`
      group
      relative flex h-full min-h-min w-full max-w-md
      flex-col justify-end overflow-hidden rounded-xl p-8 text-white shadow-lg
      ${!is_released && "pointer-events-none"}
      `}
    >
      {/* Background image with overlay */}

      <div className="absolute inset-0 z-10">
        {is_released ? (
          <>
            {/*Overlay */}
            <div className="absolute inset-0 z-10 bg-gradient-to-b from-fsc-gray-10/40 to-fsc-gray-10/95"></div>

            {/* Hover Overylay */}
            <div className="group-hover:opacity-100 opacity-0 absolute inset-0 z-10 bg-gradient-to-b from-fsc-gray-10/40 to-fsc-gray-10/95 group-hover:from-fsc-primary-blue/40 group-hover:to-fsc-primary-blue/95 transition-all"></div>
          </>
        ) : (
          <div className="absolute inset-0 z-10 bg-gradient-to-b from-fsc-gray-30/50 to-fsc-gray-30/95"></div>
        )}
        {/*Background Image */}
        <img
          src={image.src}
          alt={title + " image"}
          className="h-full w-full object-cover"
        />
      </div>

      {/*Content */}
      <article className="relative z-20">
        <h4 className="mb-2 font-jost text-2xl font-medium">
          {title} ({id})
        </h4>
        {is_released ? (
          <>
            <p className="mb-4 text-fsc-gray-90">{truncated_description}</p>
            <div className="flex items-center gap-2 text-sm font-semibold">
              <span>Learn more</span>
              <Icon icon="ic:round-keyboard-arrow-right" />
            </div>
          </>
        ) : (
          <p>Coming Soon</p>
        )}
      </article>
    </a>
  );
}

function ComingSoonBanner() {
  return (
    <div className="p-4 rounded-lg bg-white text-black uppercase">
      Coming Soon
    </div>
  );
}
